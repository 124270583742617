<template>
    <Icon
      stroke="#313C59"
      fill="transparent"
      class="icon"
    >
        <mask id="mask0_2502_26646" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
            <rect :fill-opacity="fillOpacity" width="16" height="16" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_2502_26646)">
            <path :fill-opacity="fillOpacity" d="M6 11.9987C5.63333 11.9987 5.31944 11.8681 5.05833 11.607C4.79722 11.3459 4.66667 11.032 4.66667 10.6654V2.66536C4.66667 2.2987 4.79722 1.98481 5.05833 1.7237C5.31944 1.46259 5.63333 1.33203 6 1.33203H12C12.3667 1.33203 12.6806 1.46259 12.9417 1.7237C13.2028 1.98481 13.3333 2.2987 13.3333 2.66536V10.6654C13.3333 11.032 13.2028 11.3459 12.9417 11.607C12.6806 11.8681 12.3667 11.9987 12 11.9987H6ZM6 10.6654H12V2.66536H6V10.6654ZM3.33333 14.6654C2.96667 14.6654 2.65278 14.5348 2.39167 14.2737C2.13056 14.0126 2 13.6987 2 13.332V3.9987H3.33333V13.332H10.6667V14.6654H3.33333Z" fill="#606C8B"/>
        </g>
    </Icon>
  </template>
  
  <style scoped>
  .icon {
    width: 20px;
    height: 20px;
  }
  </style>

<script>

import Icon from './Icon.vue'


/* 
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <mask id="mask0_2502_26646" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
    <rect width="16" height="16" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_2502_26646)">
    <path d="M6 11.9987C5.63333 11.9987 5.31944 11.8681 5.05833 11.607C4.79722 11.3459 4.66667 11.032 4.66667 10.6654V2.66536C4.66667 2.2987 4.79722 1.98481 5.05833 1.7237C5.31944 1.46259 5.63333 1.33203 6 1.33203H12C12.3667 1.33203 12.6806 1.46259 12.9417 1.7237C13.2028 1.98481 13.3333 2.2987 13.3333 2.66536V10.6654C13.3333 11.032 13.2028 11.3459 12.9417 11.607C12.6806 11.8681 12.3667 11.9987 12 11.9987H6ZM6 10.6654H12V2.66536H6V10.6654ZM3.33333 14.6654C2.96667 14.6654 2.65278 14.5348 2.39167 14.2737C2.13056 14.0126 2 13.6987 2 13.332V3.9987H3.33333V13.332H10.6667V14.6654H3.33333Z" fill="#606C8B"/>
  </g>
</svg>

*/
export default {
name: 'CopyIcon',
components: {
    Icon
},
props: {
    disabled: {type: Boolean, default: false },
    color: {
    type: String,
    default: "#606C8B"
    }
},
computed: {
    fillOpacity () {
    return this.disabled ? '0.18' : '1'
    }
} 
}
</script>


