<template>
  <DraftSavedModal v-if="draftSavedModalVisible" :handleClose="closeDraftSavedModal" />
  <HappDeployedModal v-if="happDeployedModalVisible" :handleClose="closeHappDeployedModal" />
  <GoToHoloFuelModal v-if="goToHoloFuelModalVisible"
    :isVisible="goToHoloFuelModalVisible" 
    :holoFuelUrl="cloudConsoleHoloFuelUrl" 
    @close="closeGoToHoloFuelModal"
    @login="loginHolofuel"
    :appName="$t('$.app_name')" 
    :dontShowModalAgainLocalStorageKey="dontShowGoToHoloFuelModalAgainLSKey" />

  <GoToSpringboardModal v-if="goToSpringboardModalVisible"
    :isVisible="goToSpringboardModalVisible"
    @login="closeGoToSpringboardModal"
    :appName="$t('$.app_name')"
    :environment="environment" />

  <ConfirmPauseModal v-if="confirmPauseModalVisible" :handleClose="closeConfirmPauseModal" />
  <HappPausedModal v-if="happPausedModalVisible" :handleClose="closeHappPausedModal" />
  <HappUnpausedModal v-if="happUnpausedModalVisible" :handleClose="closeHappUnpausedModal" />
  <ConfirmStopModal v-if="confirmStopModalVisible" :handleClose="closeConfirmStopModal" />
  <HappStoppedModal v-if="happStoppedModalVisible" :handleClose="closeHappStoppedModal" />
  <NameSetterModal
    v-if="nameSetterModalVisible"
    :handleClose="closeNameSetterModal"
    title="Welcome to Cloud Console"
    :body="nameSetterModalBody"
    :openIdentityModal="openIdentityModal"
    :updateMyName="updateMyNickname"
  />
  <IdentityModal
    v-if="identityModalVisible"
    :handleClose="closeIdentityModal"
    :body="identityModalBody"
    :name="nickname"
    :agentId="agentId"
    :agentKey="agentKey"
  />
  <ConfirmPaySelectedModal v-if="confirmPaySelectedModalVisible" :handleClose="closeConfirmPaySelectedModal" />
  <ProcessingPaymentModal v-if="processingPaymentModalVisible" :handleClose="closeProcessingPaymentModal" />
  <NotificationModal v-if="notificationModalVisible" :handleClose="closeNotificationModal" />
  <NotificationToast v-if="notificationToastVisible" :handleClose="hideNotificationToast" />

</template>

<script>
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useUiStore, useHolofuelStore, useHoloStore, useNotificationStore } from 'src/stores'

import {addObserver,removeObserver,EProjectNotification} from 'src/utils/notifications'
import { kDontShowGoToHoloFuelModalAgainLSKey } from 'src/utils'

import DraftSavedModal from 'components/DraftSavedModal.vue'
import HappDeployedModal from 'components/HappDeployedModal.vue'
import GoToHoloFuelModal from "@uicommon/components/GoToHoloFuelModal.vue"
import GoToSpringboardModal from '@uicommon/components/GoToSpringboardModal.vue'
import ConfirmPauseModal from 'components/ConfirmPauseModal.vue'
import HappPausedModal from 'components/HappPausedModal.vue'
import HappUnpausedModal from 'components/HappUnpausedModal.vue'
import ConfirmStopModal from 'components/ConfirmStopModal.vue'
import HappStoppedModal from 'components/HappStoppedModal.vue'
import NameSetterModal from '@uicommon/components/NameSetterModal.vue'
import IdentityModal from "@uicommon/components/IdentityModal.vue"
import ConfirmPaySelectedModal from 'components/ConfirmPaySelectedModal.vue'
import ProcessingPaymentModal from 'components/ProcessingPaymentModal.vue'
import NotificationModal from 'components/NotificationModal.vue'
import NotificationToast from 'components/NotificationToast.vue'

export default {
  name: 'Modals',
  components: {
    DraftSavedModal,
    HappDeployedModal,
    ConfirmPauseModal,
    HappPausedModal,
    HappUnpausedModal,
    ConfirmStopModal,
    HappStoppedModal,
    NameSetterModal,
    IdentityModal,
    GoToHoloFuelModal,
    GoToSpringboardModal,
    ConfirmPaySelectedModal,
    ProcessingPaymentModal,
    NotificationModal,
    NotificationToast,
},
  data () {
    return {
      dontShowGoToHoloFuelModalAgainLSKey: kDontShowGoToHoloFuelModalAgainLSKey,
      nameSetterModalBody: 'Cloud Console comes coupled with an associated HoloFuel account. Give these associated accounts an Account Display Name. This will show up on invoices and transactions between you and other HoloFuel users. You will NOT be able to change the name once saved.',
      identityModalBody: 'This Identicon design and hash ID are both unique representations of this Cloud Console and associated HoloFuel address. Consider this address like a bank account, or crypto wallet. When you see this identicon, you know it’s your account.',
      environment: process.env.VUE_APP_ENV
    }
  },
  mounted() {
    addObserver(EProjectNotification.showGoToHolofuelModal, this.openGoToHoloFuelModal)
    addObserver(EProjectNotification.hideGoToHolofuelModal, this.closeGoToHoloFuelModal)
  },
  unmounted() {
    removeObserver(EProjectNotification.showGoToHolofuelModal, this.openGoToHoloFuelModal)
    removeObserver(EProjectNotification.hideGoToHolofuelModal, this.closeGoToHoloFuelModal)
  },  
  methods: {
    ...mapActions(useUiStore, [
       // These three require additional clean up when closing
      'closeHappDeployedModal',
      'closeConfirmPauseModal',
      'closeConfirmStopModal',
      'openGoToHoloFuelModal',
      'closeGoToHoloFuelModal',
      'openGoToSpringboardModal',
      'closeGoToSpringboardModal',
    ]),
    ...mapActions(useNotificationStore, { hideNotificationToast: 'hideToast' }),
    ...mapActions(useHolofuelStore, ['updateMyNickname']),
    closeDraftSavedModal () {
      this.draftSavedModalVisible = false
    },
    closeHappPausedModal () {
      this.happPausedModalVisible = false
    },
    closeHappUnpausedModal () {
      this.happUnpausedModalVisible = false
    },
    closeHappStoppedModal () {
      this.happStoppedModalVisible = false
    },
    closeNameSetterModal () {
      this.nameSetterModalVisible = false
    },
    openIdentityModal () {
      this.identityModalVisible = true
    },
    closeIdentityModal () {
      this.identityModalVisible = false
    },
    openGoToSpringboardModal () {
      this.goToSpringboardModalVisible = true
    },
    closeGoToSpringboardModal () {
      this.goToSpringboardModalVisible = false
    },
    loginHolofuel (dontShowGoToHoloFuelModalAgain) {
      console.log(`loginHolofuel: ${dontShowGoToHoloFuelModalAgain}`)
      if(dontShowGoToHoloFuelModalAgain) {
        this.dontDisplayConfirmLinkToHoloFuelMessageAgain = dontShowGoToHoloFuelModalAgain
      }
    },
    closeConfirmPaySelectedModal () {
      this.confirmPaySelectedModalVisible = false
    },
    closeProcessingPaymentModal () {
      this.processingPaymentModalVisible = false
    },
    closeNotificationModal () {
      this.notificationModalVisible = false
    }
  },
  computed: {
    ...mapState(useHoloStore, ['agentId','agentKey']),
    ...mapState(useHolofuelStore, ['nickname']),
    ...mapState(useUiStore, ['confirmStopModalVisible','confirmPauseModalVisible','happDeployedModalVisible', 'cloudConsoleHoloFuelUrl', 'confirmPaySelectedModalVisible']),
    ...mapState(useNotificationStore, { notificationToastVisible: 'toastVisible' }),    
    ...mapWritableState(useUiStore, [
      'draftSavedModalVisible',
      'happPausedModalVisible',
      'happUnpausedModalVisible',
      'happStoppedModalVisible',
      'nameSetterModalVisible',
      'identityModalVisible',
      'goToHoloFuelModalVisible',
      'goToSpringboardModalVisible',
      'dontDisplayConfirmLinkToHoloFuelMessageAgain',
      'confirmPaySelectedModalVisible',
      'processingPaymentModalVisible',
      'notificationModalVisible',
    ]),
  }
}
</script>

<style scoped>
</style>
