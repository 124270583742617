<template>
  <Modal :handleClose="handleClose" modalClass='notification-modal'>
    <h2 class="title">Notifications</h2>
    <div v-if="sortedNotificationInfos.length === 0" class="empty-state">
      You have no notifications.
    </div>
    <div v-else v-for="(notificationInfo, index) of sortedNotificationInfos" :class="['card-wrapper', { separator: index !== sortedNotificationInfos.length - 1}]">
      <div v-if="isNew(notificationInfo.id)" class="new-notification" />
      <NotificationCard :notificationInfo="notificationInfo" :closeParent="handleClose" />
    </div>
  </Modal>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useNotificationStore, useHolofuelStore } from 'src/stores'

import Modal from '@uicommon/components/Modal'
import Button from '@uicommon/components/Button.vue'
import CircledExclamationIcon from '@uicommon/components/icons/CircledExclamationIcon'
import CircledCheckIcon from '@uicommon/components/icons/CircledCheckIcon'
import CircleSpinner from '@uicommon/components/CircleSpinner'
import NotificationCard from './NotificationCard'
 
export default {
  name: 'ProcessingPaymentModal',
  components: {
    Modal,
    Button,
    CircledExclamationIcon,
    CircledCheckIcon,
    CircleSpinner,
    NotificationCard
  },
  props: {
    handleClose: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      newNotifications: []
    }
  },
  mounted () {
    this.newNotifications = Object.keys(this.notifications).filter(notificationId => this.notifications[notificationId].unread)
    this.markAllRead()      
  },
  methods: {
    ...mapActions(useHolofuelStore, ['bulkPayInvoices']),
    ...mapActions(useNotificationStore, ['markAllRead']),
    isSelected (invoiceId) {
      return !!this.selectedInvoiceIds[invoiceId]
    },
  },
  computed: {
    ...mapState(useNotificationStore, ['toastInfo', 'notifications', 'notificationInfo']),
    sortedNotificationInfos () {
      return Object.keys(this.notifications)
        .filter(notificationId => this.notifications[notificationId].visible)
        .reduce((notificationInfos, notificationId) => (notificationInfos.concat(this.notificationInfo(notificationId))), [])
        .sort((a, b) => a.dueDate < b.dueDate ? -1 : 1)
    },
    isNew() {
      return notificationId =>
        this.newNotifications.includes(notificationId)
    }
  }
}
</script>

<style>
.notification-modal.modal {
  max-width: 675px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 62px;
  margin: 0 0 auto 0;
  top: 90px;
  right: 256px;
  position: absolute;
}
.notification-modal.modal .close-button {
  top: 18px;
  right: 18px;
}
</style>

<style scoped>
.title {
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: #313C59;
}
.empty-state {
  margin: 42px 116px;
  font-size: 14px;
  font-weight: 600;  
  color: #606C8B;
}
.card-wrapper {
  position: relative;
  margin-top: 10px;
  padding-bottom: 14px;
  padding-left: 26px;
}
.separator {
  border-bottom: 1px solid rgba(188, 191, 198, 0.5);
}
.new-notification {
  position: absolute;
  top: 11px;
  left: 5px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: #2172F2;
}
</style>
