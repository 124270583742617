<template>
  <router-view />
  <notification-watcher />
</template>

<script>
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useHolofuelStore, useHoloStore, useHhaStore, useNotificationStore, useHappStatsStore, useHbsStore } from 'src/stores'
import NotificationWatcher from 'src/components/NotificationWatcher.vue'
import { categoryList } from 'src/mockData'

const LOAD_INVOICES_INTERVAL = 60_000

export default {
  name: 'App',
  components: {
    NotificationWatcher
  },
  data () {
    return {
      loadInvoicesInterval: null
    }
  },
  async mounted() {
    console.log('^&* debug Jun 26 1:22pm')
    console.log('^&* categoryList', categoryList)

    try {
      await this.initialize()
      await this.initializeNotificationStore()
      if (this.isAnonymous) {
        this.openAuthForm()
      }
    } catch (e) {
      console.error('Holo store init error', e)
    }
  },
  methods: {
    ...mapActions(useHolofuelStore, ['loadMyNickname','loadLedger', 'loadInvoices']),
    ...mapActions(useHhaStore, ['loadHapps', 'fetchHoloportPricingAndJurisdictions', 'updatePublisherJurisdiction']),
    ...mapActions(useHoloStore, ['initialize', 'signIn', 'signUp', 'signOut']),
    ...mapActions(useHbsStore, ['loadAgent', 'loadAgentKycLevel', 'loadJurisdictions']),
    ...mapActions(useNotificationStore, { initializeNotificationStore: 'initialize'}),
    ...mapActions(useHappStatsStore, ['loadDashboardStats']),

    openAuthForm () {
      if (this.$route.path == '/signup') {
        this.signUp()
      } else {
        this.signIn()
      }
    }
  },
  computed: {
    ...mapWritableState(useHoloStore, ['isAuthFormOpen']),
    ...mapState(useHoloStore, ['isAnonymous', 'isLoggedIn']),
  },
  watch: {
    async isLoggedIn(is_logged_in) {
      if (is_logged_in) {
        this.isAuthFormOpen = false
        Promise.all([
          this.loadHapps(),
          this.fetchHoloportPricingAndJurisdictions(process.env.VUE_APP_ENV, process.env.VUE_APP_HBS_SERVICE_PORT)
        ])
        this.loadMyNickname()
        this.loadLedger()
        this.loadAgentKycLevel(process.env.VUE_APP_ENV, process.env.VUE_APP_HBS_SERVICE_PORT)
        this.$router.push(this.$route.params.nextUrl || '/dashboard')
        this.loadInvoices()
        this.loadInvoicesInterval = setInterval(() => this.loadInvoices(), LOAD_INVOICES_INTERVAL)

        this.loadJurisdictions(process.env.VUE_APP_ENV, process.env.VUE_APP_HBS_SERVICE_PORT)
        this.loadDashboardStats(7, process.env.VUE_APP_ENV, process.env.VUE_APP_HBS_SERVICE_PORT)

        this.loadAgent(
            process.env.VUE_APP_ENV,
            process.env.VUE_APP_HBS_SERVICE_PORT
        ).then(
            publisherJurisdiction => updatePublisherJurisdiction({
              pubkey: useHoloStore().agentId,
              jurisdiction: publisherJurisdiction
          })
        )
      } else {
        clearInterval(this.loadInvoicesInterval)
        this.loadInvoicesInterval = null
      }
    },
    isAnonymous(is_anonymous) {
      if (is_anonymous && !this.isAuthFormOpen) {
        this.openAuthForm()
      }
    },
  },
}
</script>

<style>
@import '../ui-common-library/artifacts/styles/common.css';
@import './common.css';

html {
  overflow-y: scroll;
}
body,
html {
  height: 100%;
}
#app {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafcfe;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
