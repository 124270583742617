<template>
  <Modal :handleClose="handleClose" modalClass='processing-payment-modal'>
    <div class='content'>
      <template v-if="isStillProcessing">
        <CircleSpinner/>
        <h1 class="title">Processing Payment</h1>
        <p>
          Leave this dialog open if you would like to view the status of your invoice payments as they complete. 
          You may also close this dialog at any time and your transactions will continue to process as long as this browser tab remains open. 
          If you would like to check the status of your invoice payment later, you can view them anytime from your HoloFuel account.
        </p>
      </template>
      <template v-else-if="failedInvoices.length > 0">
        <CircledExclamationIcon color="#D9001A"/>
        <h1>Payment Incomplete</h1>
        <p>
          Payment was not successful for all invoices.<br />Please review and try again later.
        </p>
      </template>
      <template v-else>
        <CircledCheckIcon color="#14BE72" />
        <h1>Payment Complete</h1>
        <p>
          Payment was made successfully.
        </p>
      </template>
    </div>
    <div class="invoice-table">
      <div class="row">
        <div class="label">
          Unpaid Invoices
        </div>
        <div class="value">
          {{ unpaidInvoices.length }}
        </div>
      </div>
      <div class="row">
        <div class="label">
          Amount to be paid:
        </div>
        <div class="value">
          {{ presentFixedDecimalAmount(amountToBePaid) }} HF
        </div>
      </div>
      <div class="row">
        <div class="label">
          Currently countersigning:
        </div>
        <div class="value">
          {{ countersigningInvoices.length }}
        </div>
      </div>
      <div class="row">
        <div class="label">
          Completed:
        </div>
        <div class="value">
          {{ paidInvoices.length }}
        </div>
      </div>
      <div class="row">
        <div class="label">
          Amount paid:
        </div>
        <div class="value">
          {{ presentFixedDecimalAmount(paidInvoicesTotal) }} HF
        </div>
      </div>
      <div class="row" v-if="failedInvoices.length > 0">
        <div class="label">
          Failed:
        </div>
        <div class="value">
          {{ failedInvoices.length }}
        </div>
      </div>
    </div>
    <div class='buttons'>
      <Button class='close' color='secondary' @click="handleClose">Close</Button>
    </div>
  </Modal>
</template>

<script>
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useUiStore, useHolofuelStore } from 'src/stores'

import Modal from '@uicommon/components/Modal'
import Button from '@uicommon/components/Button.vue'
import CircledExclamationIcon from '@uicommon/components/icons/CircledExclamationIcon'
import CircledCheckIcon from '@uicommon/components/icons/CircledCheckIcon'
import CircleSpinner from '@uicommon/components/CircleSpinner'
import { presentFixedDecimalAmount } from 'src/utils'
 
export default {
  name: 'ProcessingPaymentModal',
  components: {
    Modal,
    Button,
    CircledExclamationIcon,
    CircledCheckIcon,
    CircleSpinner
  },
  props: {
    handleClose: {
      type: Function,
      required: true
    }
  },
  methods: {
    ...mapActions(useHolofuelStore, ['bulkPayInvoices']),
    isSelected (invoiceId) {
      return !!this.selectedInvoiceIds[invoiceId]
    },
    presentFixedDecimalAmount,
  },
  computed: {
    ...mapWritableState(useUiStore, ['hcErrorMessage']),
    ...mapState(useHolofuelStore, ['actionableHostingInvoices', 'completedHostingInvoices', 'acceptedHostingInvoices', 'failedHostingInvoices']),
    ...mapState(useUiStore, ['inProcessInvoiceIds']),
    unpaidInvoices () {
      return this.actionableHostingInvoices.filter(actionableInvoice => this.inProcessInvoiceIds.find(inProcessInvoiceId => actionableInvoice.id === inProcessInvoiceId))
    },
    paidInvoices () {
      return this.completedHostingInvoices.filter(completedInvoice => this.inProcessInvoiceIds.find(inProcessInvoiceId => completedInvoice.id === inProcessInvoiceId))
    },
    countersigningInvoices () {
      return this.acceptedHostingInvoices.filter(acceptedInvoice => this.inProcessInvoiceIds.find(inProcessInvoiceId => acceptedInvoice.id === inProcessInvoiceId))
    },
    failedInvoices () {
      return this.failedHostingInvoices.filter(failedInvoice => this.inProcessInvoiceIds.find(inProcessInvoiceId => failedInvoice.id === inProcessInvoiceId))
    },
    amountToBePaid () {
      return String(
        this.unpaidInvoices.reduce((total, invoice) => total + Number(invoice.amount), 0)
        + this.countersigningInvoices.reduce((total, invoice) => total + Number(invoice.amount), 0)
        + this.failedInvoices.reduce((total, invoice) => total + Number(invoice.amount), 0)        
      )
    },
    paidInvoicesTotal () {
      return String(this.paidInvoices.reduce((total, invoice) => total + Number(invoice.amount), 0))
    },
    isStillProcessing () {
      return this.unpaidInvoices.length > 0 || this.countersigningInvoices.length > 0
    }
  }
}
</script>

<style>
.processing-payment-modal.modal {
  max-width: 675px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 62px;
}
</style>

<style scoped>
.content {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #313C59;
  padding: 0 74px;
}
.title {
  font-size: 22px;
  font-weight: 600;
  color: #313C59;
  margin-top: 40px;
  margin-bottom: 22px;
}
.invoice-table {
  margin: 28px 128px 0 128px;
  width: 418px;
}
.row {
  display: flex;
  margin-bottom: 18px;
}
.label {
  color: #7482A6;
  font-size: 14px;
}
.value {  
  margin-left: auto;
  font-weight: 700;
  color: #313C59;
  font-size: 14px;
}
.buttons {
  display: flex;
  margin: 16px 0;
}
.buttons .edit {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}
.buttons .close {
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  width: 266px;
}
</style>
