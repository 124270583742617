<template>
  <BaseTableRow>
    <BaseTableRowItem
      is-visible-on-mobile>

    <BaseCheckbox
      v-if="invoice.status === 'Unpaid'"
      :id="invoice.id + '-checkbox'"
      :checked="isSelected"
      @update:checked="(value) => emit('update:checked', value)" />
    <div v-else />
    </BaseTableRowItem>

    <BaseTableRowItem
      :value="invoice.happName"
      is-visible-on-mobile
      is-bold
    />

    <BaseTableRowItem
      :value="invoice.date"
    />

    <BaseTableRowItem>
      <div
        :id="invoice.id + '-copy-id-row'"
        class='transaction-link'
        @click="copyInvoiceIdToClipboard(invoice.id)"
        @mouseenter="showInvoiceIdTooltip(invoice.id)"
        @mouseleave="hideInvoiceIdTooltip()"
      >
        <span :id=invoice.id>{{ invoice.formattedId }}</span>
        <div
          v-if="shouldShowToolTip(invoice.id)"
          class='tooltip-wrapper'
          :data-testid="'invoice-id-tooltip-' + invoice.id"
        >
          <div class='tooltip'>
            <span>{{ invoice.id }}</span><br />- {{isInvoiceIdCopied ? 'Copied' : 'Click to copy' }}
          </div>
        </div>
      </div>
    </BaseTableRowItem>

    <BaseTableRowItem
      :value="invoice.formattedAmount"
      is-visible-on-mobile
      align="end"
    />

    <BaseTableRowItem
      :value="invoice.status"
      is-visible-on-mobile
    />

    <BaseTableRowItem
      is-visible-on-mobile>
      <div :class="['jurisdiction', { excluded: invoice.isJurisdictionExcluded }]">
        {{ invoice.jurisdiction }}
      </div>
    </BaseTableRowItem>
  </BaseTableRow>
</template>

<script setup>
import BaseTableRow from '@uicommon/components/BaseTableRow'
import BaseTableRowItem from '@uicommon/components/BaseTableRowItem'
import BaseCheckbox from '@uicommon/components/BaseCheckbox.vue';
import { copyToClipboard as copyToClipboardRaw  } from '@uicommon/utils/clipboardUtils'
import { ref } from 'vue'

const invoiceIdTooltip = ref(null)
const isCopied = ref(false)

defineProps({
  invoice: {
    type: Object,
    required: true
  },
  isSelected: {
    type: Boolean,
    required: true
  }
})

function shouldShowToolTip (id) {
  return invoiceIdTooltip.value === id
}
function showInvoiceIdTooltip (id) {
  invoiceIdTooltip.value = id
  isCopied.value = false
}
function hideInvoiceIdTooltip () {
  invoiceIdTooltip.value = null
  isCopied.value = false
}
function copyInvoiceIdToClipboard (id) {
  copyToClipboardRaw(id)
  isCopied.value = true
}

const emit = defineEmits(['update:checked'])
</script>

<style scoped>
.jurisdiction {
  border-radius: 16px;
  padding: 3px 8px;
  font-size: 12px;
}
.excluded {
  color: #B42318;
  background: #FEF3F2;
}

.transaction-link {
  width: 7rem;
  display: flex;
  justify-content: space-between;
}

.base-table-row::v-deep {
  color: #606C8B;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;    
}

.transaction-link-icon {
  width: 17px;
  margin-left: 8px;
  color: var(--grey-dark-color);
  cursor: pointer;
}
</style>