import commonTranslations from '@uicommon/locales/en'

const translations = {
  $: {
    ...commonTranslations.$,
    app_name: 'Cloud Console',
  },
  holofuel_modal: {
    ...commonTranslations.holofuel_modal
  },
  login: {
    ...commonTranslations.login,
    title: 'Host Console Login'
  },
  invoices: {
    empty_state: 'You have no invoices.'
  }
}

export default translations