<template>
  <Icon :fill="color" class='icon'>
    <g clip-path="url(#clip0_2513_424)">
      <path d="M7.9448 15.3245C11.9915 15.3245 15.2835 12.0325 15.2835 7.98582C15.2835 3.93915 11.9915 0.646484 7.9448 0.646484C3.89813 0.646484 0.605469 3.93848 0.605469 7.98582C0.606135 12.0325 3.89813 15.3245 7.9448 15.3245ZM7.9448 2.10515C11.1881 2.10515 13.8248 4.74315 13.8248 7.98582C13.8248 11.2278 11.1881 13.8658 7.9448 13.8658C4.70214 13.8658 2.06414 11.2278 2.06414 7.98582C2.0648 4.74315 4.7028 2.10515 7.9448 2.10515Z" fill="#735CFE"/>
      <path d="M7.94515 9.54984C8.34782 9.54984 8.67449 9.22384 8.67449 8.81984V4.93051C8.67449 4.52784 8.34715 4.20117 7.94515 4.20117C7.54315 4.20117 7.21582 4.52784 7.21582 4.93051V8.81984C7.21582 9.22384 7.54315 9.54984 7.94515 9.54984Z" fill="#735CFE"/>
      <path d="M7.94515 11.7675C8.34782 11.7675 8.67449 11.4415 8.67449 11.0382V10.9168C8.67449 10.5142 8.34715 10.1875 7.94515 10.1875C7.54315 10.1875 7.21582 10.5135 7.21582 10.9168V11.0382C7.21582 11.4415 7.54315 11.7675 7.94515 11.7675Z" fill="#735CFE"/>
    </g>
    <defs>
      <clipPath id="clip0_2513_424">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </Icon>
</template>
  
<script>

import Icon from './Icon.vue'

export default {
  name: 'BangIcon',
  components: {
    Icon
  },
  props: {
    disabled: {type: Boolean, default: false },
    color: {
      type: String,
      default: "#735CFE"
    }
  },
  computed: {
    fillOpacity () {
      return this.disabled ? '0.18' : '1'
    }
  }  
}
</script>

<style scoped>
.icon {
  width: 26px;
  height: 26px;
}
</style>  