<script setup>
import PrimaryLayout from 'components/PrimaryLayout.vue'
import HAppCard from '@uicommon/components/HAppCard.vue'
import HAppImage from '@uicommon/components/HAppImage.vue'
import SortByDropdown from '@uicommon/components/SortByDropdown.vue'
import BaseSearchInput from '@uicommon/components/BaseSearchInput.vue'
import PlusInCircleIcon from '@uicommon/components/icons/PlusInCircleIcon.vue'
import ArrowIcon from '@uicommon/components/icons/ArrowIcon.vue'
import BaseChip from '@uicommon/components/BaseChip.vue'
import { kSortOptions } from '@uicommon/components/constants/sortOptions'
import { EChipType } from '@uicommon/types/ui'
import { ref, computed, onMounted } from 'vue'
import { useHhaStore, useHappStatsStore } from 'src/stores'
import { emptyHappStatistics } from 'src/utils/hAppStatistics'

const hhaStore = useHhaStore()
const hAppStatsStore = useHappStatsStore()
const isError = ref(false)

const filterValue = ref('')
const filterIsActive = ref(false)

function setFilter({ value, isActive }) {
  filterIsActive.value = isActive
  filterValue.value = value
}

const sortBy = ref(kSortOptions.alphabetical.value)

const isLoading = computed(() => {
  return hhaStore.getMyHappsIsLoading
})

const hApps = computed(() => {
  return hhaStore.happs
})

const hApp_statistics = computed(() => {
  return hAppStatsStore.allHappStatistics
})

// get stats for list of happs page
const hAppStats = (happ) => {
  // NB: The get list of happ stats uses the same type as get dashboard happ stats, returning an array of the `happIds` used to calculate the stats.
  // When fetching the list of happ stats, this happIds field will only ever include a single happ id - the one respective to the current happ stats.
  // Consequently, `hAppStats.happIds[0]` should be the current happ id.
  const current_hApp_stats = hApp_statistics.value?.find(hAppStats => hAppStats.happIds[0] === happ?.id);
  if( current_hApp_stats ) {
    return {...happ, usage: current_hApp_stats.usage}
  } else {
    return {...happ, usage: emptyHappStatistics.usage}
  }
}

onMounted(() => {
  const STATS_DAYS_TO_REPORT = 7

  const happIds = hhaStore.happs.map(happ => happ.id)

  hAppStatsStore.loadAllHAppStats(happIds, STATS_DAYS_TO_REPORT, process.env.VUE_APP_ENV, process.env.VUE_APP_HBS_SERVICE_PORT)
})

const filteredHapps = computed(() => {
  const sortByLogic =
    sortBy.value === kSortOptions.earnings.value
      ? (a, b) => (a.sevenDayEarnings < b.sevenDayEarnings ? 1 : -1)
      : (a, b) => (a.name > b.name ? 1 : -1)

  if (filterIsActive.value && filterValue.value) {
    return hApps.value
      .filter(({ name }) => name.toLowerCase().includes(filterValue.value.toLowerCase()))
      .sort(sortByLogic)
  }

  return [...hApps.value].sort(sortByLogic)
})

function setSortBy(value) {
  sortBy.value = value
}

function getChipProps(happ) {
  if( happ.is_draft ) {
    return { label: 'Draft', type: EChipType.warning }
  } else if( happ.is_paused ) {
    return { label: 'Paused', type: EChipType.danger }
  } else {
    return { label: 'Deployed', type: EChipType.success }
  }
}

</script>

<template>
  <PrimaryLayout
    :title="'hApps'"
    class="happs"
    :is-content-loading="isLoading"
    :is-content-error="isError"
  >

  <div class="happs__controls">
      <BaseSearchInput
        :value="filterValue"
        :is-disabled="isLoading"
        label-translation-key="$.filter_by"
        @update="setFilter"
      />

      <SortByDropdown
        :value="sortBy"
        :is-disabled="true || isLoading" 
        class="happs__sort-by-dropdown"
        @update:value="setSortBy"
      />
    </div>
    <div v-if="!isLoading && !isError">
      <div class="happs__happ-list">
        <div class="card-container" v-for="happ in filteredHapps">
          <router-link :to="{ name: 'HappDetail', params: { id: happ.id }}">
            <HAppCard
            :key="happ.id"
            :happ="hAppStats(happ)"
            class="happs__happ-list-item">
              <template #happ-image>
                  <HAppImage
                    :happ="happ"
                    class="happ-card__desktop-image"
                  />
              </template>
              <template #status-chip>
                  <BaseChip
                    :label="getChipProps(happ).label"
                    :type="getChipProps(happ).type"
                  />
              </template>

            <template #link-icon>
              <div></div>
            </template>
            </HAppCard>
          </router-link>
          <div class="link-icon">
            <router-link :to="{ name: 'HappForm', params: { id: happ.id }}" class='arrow-icon'>
              <ArrowIcon />
            </router-link>
          </div>
        </div>

        <HAppCard v-if="!filterIsActive" class="happs__happ-list-item" :isEmpty="true">
          <template v-slot:empty-content>
            <router-link to='/happ' class='add-happ'>
              <PlusInCircleIcon class='plus-in-circle' />
              Add a hApp
            </router-link>
          </template>
        </HAppCard>
      </div>
    </div>
  </PrimaryLayout>
</template>

<style lang="scss" scoped>
.happs {
  &__controls {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 9px 0;
  }

  &__happ-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(600px, 600px));
    grid-template-rows: repeat(auto-fill, 180px);
    grid-gap: 24px;
    margin-top: 12px;
    height: calc(100vh - 175px);
    overflow-y: scroll;
  }

  &__happ-list-item {
    height: 185px;
  }
}

.card-container {
  position: relative;
}
.link-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.add-happ {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:30px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #735CFE;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.arrow-icon {
  margin-left: auto;
}

.plus-in-circle {
  margin-bottom: 7px;
}

.no-filtered-happs {

}

@media screen and (max-width: 800px) {
  .happs {
    &__happ-list {
      grid-template-columns: repeat(auto-fill, minmax(97%, 97%));
      grid-template-rows: repeat(auto-fill, 215px);
      height: calc(100vh - 215px);
    }

    &__happ-list-item {
      height: 215px;
    }

    &__controls {
      justify-content: flex-end;
    }

    &__sort-by-dropdown {
      display: none;
    }
  }
}

</style>
