import { defineStore } from 'pinia'
import moment from 'moment'
import { omit } from 'lodash'
import { getDueDate, getDateLabel } from '../utils/transaction'

const NOTIFICATION_LOCAL_STORAGE_KEY = 'notifications'

const useNotificationStore = defineStore('notifications', {
  state: () => ({
    toastVisible: false,
    toastNotificationId: null,
    toastTimeout: null,
    invoiceToNotification: {}, // keyed by invoice.id
    notifications: {}, // keyed by dateLabel (see `getDateLabel`)
  }),
  actions: {
    async initialize() {
      const storedNotifications = JSON.parse(localStorage.getItem(NOTIFICATION_LOCAL_STORAGE_KEY))

      if (storedNotifications?.invoiceToNotification) {
        this.invoiceToNotification = storedNotifications.invoiceToNotification
      }

      if (storedNotifications?.notifications) {
        this.notifications = storedNotifications.notifications
      }
    },
    handleNewActionableHostingInvoices (invoices) {
      let notificationsToToast = []

      for (let i = 0; i < invoices.length; i++) {
        const invoice = invoices[i]
        let dueDate = getDueDate(invoice)
        if (moment().add(3, 'days') > dueDate) { // notifiable
          const dateLabel = getDateLabel(dueDate)
          const currentNotificationId = this.invoiceToNotification[invoice.id]

          if (!currentNotificationId) { // we're not already tracking this invoice
            this.invoiceToNotification[invoice.id] = dateLabel
            notificationsToToast.push(dateLabel)

            const currentNotification = this.notifications[dateLabel]
            if (currentNotification) {
              this.notifications[dateLabel] = {
                invoices: currentNotification.invoices.concat([invoice]),
                unread: true,
                visible: true,
              }
            } else {
              this.notifications[dateLabel] = {
                invoices: [invoice],
                unread: true,
                visible: true,
              }
            }
          }
        }
      }

      if (notificationsToToast.length === 1) {
        this.showToast(notificationsToToast[0])
      } else if (notificationsToToast.length > 1) {
        // show the most urgent notification
        let mostUrgent = notificationsToToast.sort((a, b) => moment(a) < moment(b) ? -1 : 1)[0] // we use the fact that notificationIds are ISO dates
        this.showToast(mostUrgent)
      }
      this.saveToStorage()
    },
    handleCompletedHostingInvoices (invoices) {
      for (let i = 0; i < invoices.length; i++) {
        const completedInvoice = invoices[i]
        const notificationId = this.invoiceToNotification[completedInvoice.id]

        if (notificationId) { // this completed invoice is associated with a notification
          this.invoiceToNotification[completedInvoice.id] = null
          this.notifications[notificationId].invoices = this.notifications[notificationId].invoices.filter(notifiedInvoice => notifiedInvoice.id !== completedInvoice.id)
          if (this.notifications[notificationId].invoices.length === 0) { // the invoice is now redundant
            this.notifications = omit(this.notifications, notificationId)
          }
        }
      }
      this.saveToStorage()
    },
    showToast (notificationId, duration = 7_500) {
      if (this.toastTimeout) {
        clearTimeout(this.toastTimeout)
      }
      this.toastNotificationId = notificationId
      this.toastVisible = true
      this.toastTimeout = setTimeout(() => {
        this.hideToast()
        this.toastTimeout = null
      }, duration);      
    },
    hideToast () {
      this.toastVisible = false
    },
    markAllRead () {
      const notificationIds = Object.keys(this.notifications)
      for (let i = 0; i < notificationIds.length; i++) {
        const notificationId = notificationIds[i]
        this.notifications[notificationId].unread = false
      }
      this.saveToStorage()

    },
    dismissNotification (notificationId) {
      this.notifications[notificationId].visible = false
      this.hideToast() // this is horrible coupling
      this.saveToStorage()
    },
    saveToStorage () {
      localStorage.setItem(NOTIFICATION_LOCAL_STORAGE_KEY, JSON.stringify({
        invoiceToNotification: this.invoiceToNotification,
        notifications: this.notifications
      }))
    }
  },
  getters: {
    notificationInfo () {
      return notificationId => {
        const notification = this.notifications[notificationId]

        if (!notification) {
          return null        
        }
  
        return notification.invoices.reduce((notificationInfo, invoice) => ({
          ...notificationInfo,
          numInvoices: notificationInfo.numInvoices + 1,
          totalAmount: notificationInfo.totalAmount + Number(invoice.amount),
          dueDate: getDueDate(invoice),
          overdue: moment() > getDueDate(invoice) || notificationInfo.overdue,
        }), {
          id: notificationId,
          numInvoices: 0,
          totalAmount: 0,
          dueDate: null,
          overdue: false,
          unread: notification.unread
        })
      }
    },
    toastInfo () {
      return this.notificationInfo(this.toastNotificationId)
    },
    hasUnread () {
      return Object.keys(this.notifications)
        .reduce((anyUnread, notificationId) => anyUnread || (this.notifications[notificationId].visible && this.notifications[notificationId].unread), false)
    }
  }

})

export default useNotificationStore
