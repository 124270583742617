import { defineStore } from 'pinia'
import { kDontShowGoToHoloFuelModalAgainLSKey } from 'src/utils'

const useUiStore = defineStore('ui', {
  state: () => ({
    flashMessage: null,
    draftSavedModalVisible: false,
    happDeployedModalVisible: false,
    goToHoloFuelModalVisible: false,
    goToSpringboardModalVisible: false,
    confirmPauseModalVisible: false,
    happPausedModalVisible: false,
    happUnpausedModalVisible: false,
    confirmStopModalVisible: false,
    happStoppedModalVisible: false,
    nameSetterModalVisible: false,
    identityModalVisible: false,
    confirmPaySelectedModalVisible: false,
    processingPaymentModalVisible: false,
    notificationModalVisible: false,
    lastDeployedHapp: null,
    happToPauseId: null,
    happToStopId: null,
    hcErrorMessage: null,
    selectedInvoiceIds: {},
    inProcessInvoiceIds: [],
    dontDisplayConfirmLinkToHoloFuelMessageAgain:
      (localStorage.getItem(kDontShowGoToHoloFuelModalAgainLSKey) === 'true'),
    cloudConsoleHoloFuelUrl: process.env.VUE_APP_CLOUD_CONSOLE_HOLOFUEL_URL
      ? process.env.VUE_APP_CLOUD_CONSOLE_HOLOFUEL_URL : "http://localhost:8081",
    springboardUrl: process.env.VUE_APP_SPRINGBOARD_URL
      ? process.env.VUE_APP_SPRINGBOARD_URL : "https://springboard.dev.holotest.net"
  }),
  actions: {
    openHappDeployedModal (happ) {
      this.happDeployedModalVisible = true
      this.lastDeployedHapp = happ
     },
    closeHappDeployedModal () {
      this.happDeployedModalVisible = false
      this.lastDeployedHapp = null
    },
    openGoToHoloFuelModal () {
      this.goToHoloFuelModalVisible = true
    },
    closeGoToHoloFuelModal () {
      this.goToHoloFuelModalVisible = false
    },
    openGoToSpringboardModal () {
      this.goToSpringboardModalVisible = true
    },
    closeGoToSpringboardModal () {
      this.goToSpringboardModalVisible = false
    },
    openConfirmPauseModal (happToPauseId) {
      this.confirmPauseModalVisible = true
      this.happToPauseId = happToPauseId
    },
    closeConfirmPauseModal () {
      this.confirmPauseModalVisible = false
      this.happToPauseId = null
    },
    openConfirmPaySelectedModal () {
      this.confirmPaySelectedModalVisible = true
    },
    openConfirmStopModal (happToStopId) {
      this.confirmStopModalVisible = true
      this.happToStopId = happToStopId
    },
    closeConfirmStopModal () {
      this.confirmStopModalVisible = false
      this.happToStopId = null
    },
    openNameSetterModal () {
      this.nameSetterModalVisible = true
    },
    closeNameSetterModal () {
      this.nameSetterModalVisible = false
    },
    openIdentityModal () {
      this.identityModalVisible = true
    },
    closeIdentityModal () {
      this.identityModalVisible = false
    },
    openNotificationModal () {
      this.notificationModalVisible = true
    },
    setInvoiceSelectionStatus (invoiceId, isSelected) {
      this.selectedInvoiceIds[invoiceId] = isSelected
    },
    clearInvoiceSelection () {
      this.selectedInvoiceIds = {}
    }
  }
})

export default useUiStore
