<template>
  <div class="toast">
    <NotificationCard :handleCardClick="handleCardClick" :notificationInfo="toastInfo" :closeParent="handleClose" />
    <button @click="handleClose" class="close-button"><ExIcon class="ex-icon" :size="16" data-testid='close-toast-button'/></button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useNotificationStore, useUiStore } from 'src/stores'

import BaseButton from '@uicommon/components/BaseButton.vue'
import ExIcon from '@uicommon/components/icons/ExIcon'
import BellIcon from '@uicommon/components/icons/BellIcon'
import NotificationCard from './NotificationCard'
 
export default {
  name: 'NotificationToast',
  components: {
    ExIcon,
    BellIcon,
    BaseButton,
    NotificationCard
},
  props: {
    handleClose: {
      type: Function,
      required: true
    }
  },
  methods: {
    ...mapActions(useUiStore, ['openNotificationModal']),
    handleCardClick () {
      this.handleClose()
      this.openNotificationModal()
    }
  },
  computed: {
    ...mapState(useNotificationStore, ['toastInfo']),
  }
}
</script>

<style scoped>
.toast {
  position: fixed;
  top: 102px;
  right: 62px;
  width: 368px;
  padding: 16px;
  border-radius: 8px;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 12px 16px -4px #1018281A;
}
.row {
  display: flex;
}
.column {
  margin: 0 12px;
  max-width: 304px;
}
.title {
  margin: 8px 0 0 0;
  font-size: 14px;
  font-weight: 700;
  color: #313C59;
}
.body {
  margin-top: 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;  
  color: #606C8B;
}
.buttons {
  margin-top: 22px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.dismiss-button {
  padding: 8px 14px;
  margin-right: 12px;
}
.pay-button {
  padding: 8px 14px;  
}
.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
 
</style>
