<template>
  <PrimaryLayout>
    <div class='row'>
      <div class='card'>
        <h2 class='title'>hApps <Loader v-if="getMyHappsIsLoading" scale="0.5" class='loader' /></h2>
        <div class='card-content inner-row vertically-center'>
          <div class='inner-column happs-column' v-if='happs.length > 0'>
            <div v-for="happ of happs.slice(0,3)" :key="happ.hha_id">
              <div class='happ-row'>
                <router-link :to="{ name: 'HappDetail', params: { id: happ.id }}">
                  <img width='36' height='36' :src="(happ.logo_url) ? happ.logo_url : '/images/empty-image-small.svg'"/>
                </router-link>
                <div class='happ-column'>
                  <router-link :to="{ name: 'HappDetail', params: { id: happ.id }}">
                    {{ happ.name }}
                    <div v-if="happ.is_draft" class='aligned-row'>
                      <div class='draft'>Draft</div>
                    </div>
                    <div v-else-if="happ.is_paused" class='aligned-row'>
                      <div class='paused'>Paused</div>
                    </div>
                    <div v-else class='aligned-row'>
                      <div class='deployed'>Deployed</div>
                    </div>
                  </router-link>
                </div>
                <div class='happ-column happ-edit'>
                  <PencilIcon class='pencil-icon' :size="15" @click="edit(happ.id)"/>
                </div>
              </div>
            </div>
            <div class='see-all-happs'>
              <router-link to='/happs' class='see-all-link'>
                See all <RightArrowIcon class='right-arrow'/>
              </router-link>
            </div>
          </div>
          <router-link to='/happ' class='add-happ'>
            <PlusInCircleIcon class='plus-in-circle' />
            Add a hApp
          </router-link>
        </div>
      </div>
      <div class='card two-columns'>
        <h2 class='title grayed-out'>HoloFuel Balance</h2>
        <div class='card-content'>
          <div class='inner-row'>
            <div class='inner-column two-thirds'>
              <h3 class='sub-title grayed-out'>HoloFuel Burndown</h3>
              <MockGraphIcon class='mock-graph' />
            </div>
            <div class='inner-column one-third'>
              <h3 class='sub-title grayed-out'>HoloFuel Balance</h3>
              <div class='holofuel-amount grayed-out'>{{holofuelBalance}} HF</div>
              <BaseButton class='dashboard-button buy-holofuel' @click="handleBuyHoloFuelClick">Buy HoloFuel</BaseButton>
              <BaseButton class='dashboard-button open-holofuel' @click="handleGoToHoloFuelClick">Open HoloFuel</BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='card'>
        <h2 class='title grayed-out'>Billing &amp; Payments</h2>
        <div class='card-content'>
          <div class='inner-column links-column'>
            <a href='#'>Review Exceptions</a>
            <a href='#'>Autopay Settings</a>
            <a href='#'>View Invoices</a>
          </div>
        </div>
      </div>
      <div class='card two-columns'>
        <h2 class='title'>Usage &amp; Cost</h2>
        <div class='card-content'>
          <div class='inner-row'>
            <div class='inner-column two-thirds'>
              <div>
                <h3 class='sub-title'>Last 7 Days</h3>
                <table class='usage-and-cost'>
                  <tr>
                    <th></th><th>Usage</th><th>Cost</th>
                  </tr>
                  <tr>
                    <td>CPU</td><td>{{ presentAmount(dashboardStatistics.usage.cpu) }} HPU</td><td>{{ presentAmount(dashboardStatistics.holofuel.cpu) }} HF</td>
                  </tr>
                  <tr>
                    <td>Data Transfer</td><td>{{ presentAmount(dashboardStatistics.usage.bandwidth) }} Mb</td><td>{{ presentAmount(dashboardStatistics.holofuel.bandwidth) }} HF</td>
                  </tr>
                  <tr class="hidden">
                    <td>Storage</td><td>{{ presentAmount(dashboardStatistics.usage.storage) }} MB</td><td>{{ presentAmount(dashboardStatistics.holofuel.storage) }} HF</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class='hosting-stats inner-column one-third'>
              <div class="hosting-stats-value"><span class='hosting-stats-label'>Total Cost:</span>{{ presentAmount(dashboardStatistics.holofuel.total) }} HF</div>
              <div class="hosting-stats-value"><span class='hosting-stats-label'>Source Chains:</span>{{ dashboardStatistics.usage.sourceChains }}</div>
              <div class="hosting-stats-value"><span class='hosting-stats-label'># of hosts:</span>{{ dashboardStatistics.usage.hosts }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </PrimaryLayout>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useHhaStore, useUiStore, useHolofuelStore, useHappStatsStore } from 'src/stores'
import PrimaryLayout from 'components/PrimaryLayout.vue'
import Loader from '@uicommon/components/Loader.vue'
import BaseButton from '@uicommon/components/BaseButton.vue'
import PlusInCircleIcon from '@uicommon/components/icons/PlusInCircleIcon.vue'
import PencilIcon from '@uicommon/components/icons/PencilIcon.vue'
import RightArrowIcon from '@uicommon/components/icons/RightArrowIcon.vue'
import MockGraphIcon from '@uicommon/components/icons/MockGraphIcon.vue'
import { presentFixedDecimalAmount } from 'src/utils'

export default {
  name: 'Dashboard',
  components: {
    PrimaryLayout,
    Loader,
    PlusInCircleIcon,
    PencilIcon,
    RightArrowIcon,
    MockGraphIcon,
    BaseButton,
  },
  methods: {
    ...mapActions(useUiStore, ['openGoToHoloFuelModal']),
    edit(id) {
      this.$router.push({ path: `/happ/${id}`})
    },
    handleGoToHoloFuelClick() {
      if( this.dontDisplayConfirmLinkToHoloFuelMessageAgain === false) {
        this.openGoToHoloFuelModal()
      }
      else {
        const tabName = `${this.$t('$.app_name')}-hf`
        window.open(this.cloudConsoleHoloFuelUrl, tabName).focus()
      }
    },
    handleBuyHoloFuelClick() {
      window.open(this.springboardUrl)
    },
    presentAmount(amount) {
      return (amount && amount !== "--") ? presentFixedDecimalAmount(amount) : amount
    }
  },
  computed: {
    ...mapState(useHhaStore, ['happs', 'getMyHappsIsLoading']),
    ...mapActions(useHhaStore, ['happ']),
    ...mapState(useUiStore, ['dontDisplayConfirmLinkToHoloFuelMessageAgain', 'cloudConsoleHoloFuelUrl', 'springboardUrl']),
    ...mapState(useHolofuelStore, ['fullBalance'] ),
    ...mapState(useHappStatsStore, ['dashboardStatistics'] ),
    holofuelBalance() {
      return (this.fullBalance) ? this.fullBalance : "--"
    }
  }
}

</script>

<style scoped>
.loader {
  margin-top: 3px;
  margin-left: 20px;
}
.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
}
.card {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  flex-basis: 33%;
  background-color: white;
  box-shadow: 0px 4px 20px #ECEEF1;
  border-radius: 12px;
  height: 296px;
}
.card:last-child {
  margin-right: 0;
}
.two-columns {
  flex-basis: 69%;
}
.title {
  display: flex;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: rgba(199, 211, 255, 0.32);
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: black;
  padding: 10px 28px;
  margin: 0;
}
.card-content {
  padding: 22px 28px;
  height: 100%;
}
.happ-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  color: #4A546E;
  margin-bottom: 15px;
}

.happ-edit {
  align-self: flex-end;
  margin-bottom: 4px;
}
.aligned-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.draft {
  font-style: italic;
  font-weight: bold;
  color: #FFA800;
  background: rgba(255, 235, 166, 0.72);
  border-radius: 100px;
  padding: 0px 10px;
  display: inline-block;
}
.deployed {
  font-style: italic;
  font-weight: bold;
  color: #14BE72;
  background: #CBFFE3;
  border-radius: 100px;
  padding: 0px 10px;
  display: inline-block;
}
.paused {
  font-style: italic;
  font-weight: bold;
  color: #FD0D0D;
  background-color: rgba(255, 203, 203, 0.47);
  border-radius: 100px;
  padding: 0px 10px;
  display: inline-block;
}
.pencil-icon {
  cursor: pointer;
  margin-inline-start: 4px;
}
.happ-column {
  display: flex;
  flex-direction: column;
  margin-inline-start: 13px;
}
.see-all-happs {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-top: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.right-arrow {
  margin-left: 5px;
}
.add-happ {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:auto;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #735CFE;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}
.vertically-center {
  margin: auto 0px;
}
.happs-column {
  width: 200px;
  overflow: hidden;
}
.plus-in-circle {
  margin-bottom: 7px;
}
.inner-row {
  display: flex;
  flex-direction: row;
}
.inner-column {
  display: flex;
  flex-direction: column;
}
.inner-column.two-thirds {
  flex-basis: 66%;
}
.inner-column.one-third {
  flex-basis: 33%;
}
.links-column {
  margin-top: 28px;
  justify-content: space-between;
  height: 93px;
}
a {
  font-size: 14px;
  line-height: 19px;
  color: #BCBFC6;
}
.sub-title {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: black;
}
.mock-graph {
  margin-left: 12px;
  margin-top: 36px;
}
.holofuel-amount {
  margin-top: 12px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.dashboard-button {
  margin-top: 26px;
  width: 165px;
  height: 40px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}
.dashboard-button.buy-holofuel {
  background: #735CFE;
  color: #FFFFFF;
  border: 1px solid #735CFE;
}

.dashboard-button.open-holofuel {
  background: #FFFFFF;
  color: #735CFE;
  border: 1px solid #735CFE;
}

.usage-and-cost {
  margin-top: 12px;
}
th, td {
  padding-right: 36px;
}
th {
  padding-bottom: 18px;
  font-weight: normal;
}
td:not(:first-child) {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.hosting-stats {
  margin-top: 72px;
  justify-content: space-between;
  height: 72px;
  font-size: 14px;
  line-height: 19px;
}
.hosting-stats-label {
  width: 130px;
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
}

.hosting-stats-value {
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
}
.grayed-out {
  opacity: 0.4;
}

.see-all-link {
  color: #4A546E;
}

.hidden {
  display: none;
}

@media screen and (max-width: 1050px) {

}
</style>
