<template />

<script>

import { mapState, mapActions } from 'pinia'
import { useHolofuelStore, useNotificationStore } from 'src/stores'

// This component exists because the pinia store `.subscribe` events don't contain the changed value in production
// Otherwise we could just do this directly by having the notification store subscribe to the holofuel store
// If pinia ever adds this feature to production, we should do that
// https://pinia.vuejs.org/api/interfaces/pinia.SubscriptionCallbackMutationPatchObject.html#events

export default {
  name: "NotificationWatcher",    
  methods: {
    ...mapActions(useNotificationStore, ['handleNewActionableHostingInvoices', 'handleCompletedHostingInvoices'])
  },
  computed: {
    ...mapState(useHolofuelStore, ['actionableHostingInvoices', 'completedHostingInvoices'])
  },
  watch: {
    actionableHostingInvoices (newVal) {
      this.handleNewActionableHostingInvoices(newVal)
    },
    completedHostingInvoices (newVal) {
      this.handleCompletedHostingInvoices(newVal)
    },
  }
}
</script>
  
<style scoped>
</style>