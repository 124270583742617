import { defineStore } from 'pinia'
import { hAppServiceLogs, hAppStats, dashboardStats, allHappStats } from '@uicommon/services/servicelogApi'
import { generateServiceLogPayload } from '@uicommon/utils/serviceLogPayload'
import { emptyHappStatistics } from 'src/utils/hAppStatistics'

const makeUseHappStatsStore = ({ useHoloStore }) => {
    return defineStore('happ-stats', {
        state: () => ({
            dashboardStatistics: emptyHappStatistics,
            allHappStatistics: [],
            individualHappStatistics: {}
        }),
        getters: {
            happStats: state => id => state.individualHappStatistics[id] ?? emptyHappStatistics,
        },
        actions: {
            async fetchHAppServiceLogs(happId, environment, serviceLogPort) {
                const payload = generateServiceLogPayload({ "happ_id": happId })
                const { _, signature  } = await useHoloStore().signPayload(payload)
                const encoded_service_logs = await hAppServiceLogs(payload, signature, useHoloStore().agentKey, environment, serviceLogPort)
          
                return encoded_service_logs
            },
            async loadHAppStats(happId, days, environment, serviceLogPort) {
                this.happId = happId

                try {
                    const payload = generateServiceLogPayload({ "days": days.toString(), "happ_id": happId })
                    const { _, signature  } = await useHoloStore().signPayload(payload)
                    const hAppStatistics = await hAppStats(payload, signature, useHoloStore().agentKey, environment, serviceLogPort)
            
                    individualHappStatistics[happId] = hAppStatistics
                } catch (e) {
                    console.error('Error fetching hApp stats', e.message)
                    return emptyHappStatistics
                }
            },
            async loadAllHAppStats(happIds, days, environment, serviceLogPort) {
                try {
                    // NB: The happ_ids param needs to be a string to succeed the msgpack serialization
                    // and validation check in the server.
                    const happIdsString = JSON.stringify(happIds)
                    const payload = generateServiceLogPayload({ "days": days.toString(), "happ_ids": happIdsString })
                    const { _, signature  } = await useHoloStore().signPayload(payload)
                    allHappStats(payload, signature, useHoloStore().agentKey, environment, serviceLogPort)
                    .then((hApp_stats) => {
                        if( hApp_stats && hApp_stats.length > 0 ) {
                            const {hAppStatsList, hAppStatsErrors } = hApp_stats.reduce((acc, res) => {
                                if (res["Ok"]) {
                                acc.hAppStatsList.push(res["Ok"])
                                } else {
                                acc.hAppStatsErrors.push(res["Err"])
                                }
                                return acc
                            }, {hAppStatsList:[], hAppStatsErrors: []});
                    
                            this.allHappStatistics = hAppStatsList
                        }
                    })
                } catch (e) {
                    console.error('Error fetching all hApp stats', e.message)
                    this.allHappStatistics = []
                }
            },
            async loadDashboardStats(days, environment, serviceLogPort) {
                try {
                    const payload = generateServiceLogPayload({ "days": days.toString() })
                    const { _, signature  } = await useHoloStore().signPayload(payload)
                    const dashboardStatistics = await dashboardStats(payload, signature, useHoloStore().agentKey, environment, serviceLogPort)
            
                    this.dashboardStatistics = dashboardStatistics
                } catch (e) {
                    console.error('Error fetching dashboard stats', e.message)
                    this.dashboardStatistics = emptyHappStatistics
                }
            }            
        }
    })
}

export default makeUseHappStatsStore
