<template>
  <section class="sidebar">
    <h1 class="banner">
      Cloud Console
    </h1>
    <section class="menu">
      <router-link to="/dashboard" class='menu-row' active-class="active-link">
        <HomeIcon class="home-icon" color='#313C59'/>Dashboard
      </router-link>
      <router-link to="/happs" class='menu-row' active-class="active-link">
        <HappsIcon class="happs-nav-icon" color='#313C59'/>hApps
      </router-link>      
      <router-link to="/billing-and-payments" class='menu-row' active-class="active-link">
        <CashIcon class="billing-and-payments-nav-icon" color='#313C59'/>Billing & Payments
      </router-link>
      <div class='alpha-banner'>
        <div class='flag'>ALPHA<div class='triangle' /></div>
        <p>
          Cloud Console is in Alpha testing. All references to HF should be interpreted as Test Fuel.
        </p>
        <p>
          <a href='https://forum.holo.host/c/alpha-testing-holoports/31' target="_blank">Learn more</a>
        </p>
      </div>
      <div class="footer">
        <a class='holo-brand' href="https://holo.host/" target="_blank">
          <img src='/images/holo-logo.png' class='holo-logo'/>
          HOLO
        </a>
        <a class='footer-link' href='https://forum.holo.host/t/alpha-testnet-terms-conditions/193' target="_blank">View Terms of Service</a>
      </div>
    </section>
  </section>
</template>

<script>
import HomeIcon from '@uicommon/components/icons/HomeIcon.vue'
import CashIcon from '@uicommon/components/icons/CashIcon.vue'
import HappsIcon from '@uicommon/components/icons/HappsIcon.vue'

export default {
  name: 'Sidebar',
  components: {
    HomeIcon,
    CashIcon,
    HappsIcon
  },
  computed: {
    uiVersion () {
      return process.env.VUE_APP_UI_VERSION
    }
  }
}
</script>

<style scoped>
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 270px;
  display: flex;
  flex-direction: column;
  flex: 0 0 270px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(54, 59, 71, 0.1);
  z-index: 100;
}
.mobile-banner .sidebar {
  display: none;
}
.banner {
  font-weight: bold;
  font-size: 26px;
  color: #735CFE;
  background-color: rgba(199, 211, 255, 0.32);
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 42px 0;
  line-height: 35px;
}
.menu {
  padding: 30px 24px 30px 18px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.menu-row {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  color: #313C59;
  margin-bottom: 20px;
  padding: 3px 0 3px 24px;
}
.menu-row:hover {
  background-color: var(--light-highlight-color);
  border-radius: 2px;
}
.home-icon {
  margin-right: 10px;
}

.billing-and-payments-nav-icon {
  margin-right: 10px;
  margin-top: 10px;
}

.happs-nav-icon {
  margin-right: 10px;
}

.alpha-banner {
  position: relative;
  margin: auto -24px auto -18px;
  padding: 35px 40px 18px 32px;
  background-color: #EDF1FF;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #313C59;
}
.alpha-banner a {
  font-weight: 600;
  color: #313C59;
}
.flag {
  position: absolute;
  top: -17px;
  height: 40px;
  margin-left: -32px;
  padding: 0 6px 0 10px;
  background: #E339FF;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: white;
}
.triangle {
  position: absolute;
  top: 0px;
  right: -18px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 18px;
  border-color: transparent transparent transparent #e539ff;
}
.footer {
  margin-top: auto;
  padding-bottom: 8px;
  padding-left: 24px;
}
.holo-brand {
  display: flex;
  margin-bottom: 25px;
  align-items: center;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 12px;
  color: black;
  text-decoration: none;
}
.holo-logo {
  width: 30px;
  margin-right: 8px;
  margin-left: -6px;
}
.footer-link {
  display: block;
  margin-bottom: 6px;
  color: black;
  font-weight: 600;
  font-size: 14px;
}

.active-link {
  color: #735CFE;
}
@media screen and (max-width: 1050px) {
  .sidebar {
    display: none;
  }
  .mobile-banner .sidebar {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    min-width: 272px;
    max-width: 72vw;
  }
  .banner {
    padding: 50px 0 51px;
  }
}

</style>
