<template>
  <Icon class='icon' viewBox="0 0 66 66" :fill="color">
    <path d="M33 66c18.2 0 33-14.8 33-33S51.2 0 33 0 0 14.8 0 33s14.8 33 33 33Zm0-59.44A26.47 26.47 0 0 1 59.44 33 26.47 26.47 0 0 1 33 59.44 26.47 26.47 0 0 1 6.56 33 26.48 26.48 0 0 1 33 6.56Z" :fill="color"/>
    <path d="M33 40.04a3.28 3.28 0 0 0 3.28-3.28v-17.5a3.28 3.28 0 0 0-6.56 0v17.5A3.28 3.28 0 0 0 33 40.04ZM33 50.01a3.28 3.28 0 0 0 3.28-3.28v-.54a3.28 3.28 0 0 0-6.56 0v.54A3.28 3.28 0 0 0 33 50.01Z" :fill="color"/>
  </Icon>
</template>

<script>

import Icon from './Icon.vue'

export default {
  name: 'CircledExclamationIcon',
  components: {
    Icon
  },
  props: {
    color: {
      type: String,
      default: "#735CFE"
    }
  }
}
</script>

<style scoped>
.icon {
  width: 66px;
  height: 66px;
}
</style>
