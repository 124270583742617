<template>
  <div class="top-nav-menu">
    <BellIcon :type="bellType" class="bell-icon" @click="openNotificationModal" data-testid="notification-button" />
    <Identicon v-if="agentKey" size="40" :agentKey="agentKey" class="identicon" :tooltipStyle="{ right: '50px' }" />
    <div class='menu-column'>
      <div class="happ-manager-name" @click.stop="toggleMenu" data-testid='top-nav-menu'>{{ happManagerName }} <DownTriangleIcon class='down-triangle' :white="white" /></div>
      <div class="verification-status">Level {{kycLevel}} Verified</div>
      <div v-if="menuOpen" v-click-outside="closeMenu" class="menu">
        <div @click="handleLogout" class="menu-item">Logout</div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@uicommon/components/Loader.vue'
import DownTriangleIcon from '@uicommon/components/icons/DownTriangleIcon.vue'
import { mapState, mapActions } from 'pinia'
import { useHoloStore, useUiStore, resetHhaStore, useHbsStore } from 'src/stores'
import Identicon from '@uicommon/components/Identicon.vue'
import BellIcon from '@uicommon/components/icons/BellIcon.vue'

export default {
  name: 'TopNav',
  components: {
    DownTriangleIcon,
    Identicon,
    Loader,
    BellIcon
},
  props: {
    happManagerName: String,
    white: Boolean,
    hasUnreadNotifications: Boolean
  },
  data () {
    return {
      menuOpen: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapState(useHoloStore, ['agentKey']),
    ...mapState(useHbsStore, ['agentKycLevel']),
    kycLevel () {
      return this.agentKycLevel ? this.agentKycLevel : 1
    },
    bellType () {
      return this.hasUnreadNotifications ? 'unread' : 'plain'
    }
  },
  methods: {
    ...mapActions(useHoloStore, ['signOut']),
    ...mapActions(useUiStore, ['openNotificationModal']),
    toggleMenu () {
      this.menuOpen = !this.menuOpen
    },
    closeMenu () {
      this.menuOpen = false
    },
    async handleLogout () {
      resetHhaStore()
      await this.signOut()
      this.$router.push('/login')
    }
  }
}
</script>

<style>
/* identicon class has to be global */
.top-nav-menu > .identicon {
  height: 2.5rem;
  position: relative;
  top: -4px;
}
</style>

<style scoped>
.top-nav-menu {
  display: flex;
  position: relative;
  margin: 8px -3px 0 auto;
  color: #313C59;
}
.bell-icon {
  margin-right: 8px;
  cursor: pointer;
}.mobile-banner .top-nav-menu {
  display: none;
}
.loader {
  margin-inline-end: 29px;
}
.menu-column {
  display: block;
  margin-right: 2.125rem;
  margin-left: 1rem;
}
.happ-manager-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  text-align: end;
  padding-right: 20px;
}
.verification-status {
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  margin-right: 38px;
}
.down-triangle {
  margin-left: 3px;
}
.menu {
  position: absolute;
  z-index: 50;
  right: 46px;
  top: 20px;
  background: #FFFFFF;
  border-radius: 2px;
  font-size: 14px;
  line-height: 19px;
  color: #606C8B;
  margin-top: 1px;
  padding: 5px 0px;
  cursor: pointer;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.mobile-banner .menu {
  right: -2px;
  top: 20px;
}
.menu::before {
  position: absolute;
  right: 7px;
  top: -5px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent white transparent;
}
.menu-item {
  padding: 0px 16px;
  margin: 7px 0;
}
.menu-item:hover {
  background-color: var(--light-highlight-color);
}

@media screen and (max-width: 1050px) {
  .top-nav-menu {
    display: none;
  }

  .mobile-banner .top-nav-menu {
    display: block;
    top: 11px;
    color: white;
  }

  .happ-manager-name {
    padding-right: 6px;
  }
  .verification-status {
    margin-right: 24px;
  }
}
</style>
