import { createWebHistory, createRouter } from "vue-router"
import Login from "pages/Login.vue"
import Dashboard from "pages/Dashboard.vue"
import BillingAndPayments from "pages/BillingAndPayments.vue"
import Happs from 'pages/Happs.vue'
import BillingAndPaymentInvoices from "pages/BillingAndPaymentInvoices.vue"
import HappForm from "pages/HappForm.vue"
import HappDetail from "pages/HappDetail.vue"
import { useHoloStore } from 'src/stores'

export const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/happs',
    name: "hApps",
    component: Happs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/billing-and-payments",
    name: "Billing&Payments",
    component: BillingAndPayments,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/billing-and-payments/invoices",
    name: "Billing&PaymentInvoices",
    component: BillingAndPaymentInvoices,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: "/happ/:id?",
    name: "HappForm",
    component: HappForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/happ-detail/:id',
    name: "HappDetail",
    component: HappDetail,
    props: true,
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const holoStore = useHoloStore()
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // page only visible when logged in

    if(!holoStore.isLoggedIn) {
      next({
        name: 'Login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    // page only visible when NOT logged in

    if(holoStore.isLoggedIn) {
      next({ name: 'Dashboard'})
    } else {
      next()
    }
  } else {
    // publicly visible page
    next()
  }
})

export default router
