<template>
  <section class='layout'>
    <Sidebar />
    <section class='main-column'>
      <MobileBanner :happManagerName="happManagerName || 'Loading...'" :showMobileSidebar="showMobileSidebar" :mobileSidebarVisible="mobileSidebarVisible" />
      <TopNav :breadcrumbs="breadcrumbsOrTitle" :happManagerName="happManagerName" />
      
      <Modals />   

      <section class='content'>
        <FlashMessage />
        <slot />
      </section>
    </section>
  </section>
</template>

<script>
import { useHolofuelStore, useUiStore } from 'src/stores'
import { mapState, mapActions } from 'pinia'
import Sidebar from 'components/Sidebar.vue'
import TopNav from 'components/TopNav.vue'
import MobileBanner from 'components/MobileBanner.vue'
import FlashMessage from 'components/FlashMessage.vue'
import Modals from 'components/Modals.vue'
import wait from 'waait'

export default {
  name: 'PrimaryLayout',
  components: {
    Sidebar,
    TopNav,
    MobileBanner,
    FlashMessage,
    Modals
  },
  props: {
    title: String,
    breadcrumbs: Array
  },
  data () {
    return {
      mobileSidebarVisible: false,
    }
  },
  methods: {
    ...mapActions(useUiStore, ['openNameSetterModal', 'closeNameSetterModal']),
    showMobileSidebar (shouldShow = false) {
      this.mobileSidebarVisible = shouldShow
    },
  },
  computed: {
    ...mapState(useHolofuelStore, { happManagerName: 'nickname', getMyProfileIsLoading: 'getMyProfileIsLoading'} ),
    breadcrumbsOrTitle() {
      if (!!this.breadcrumbs) {
        return this.breadcrumbs
      } else {
        return [{
          label: this.title
        }]
      }
    }
  },
  watch: {
    async getMyProfileIsLoading (val) {
      if (val === false) {
        await wait() // need to wait for loadMyNickname to save the nickname

        if (!this.happManagerName) {
          this.openNameSetterModal();
        }
      }
    },
  }  
}
</script>

<style scoped>
.layout {
  display: flex;
  height: 100%;
  /* Making room for the sidebar */
  padding-left: 270px;
}
.main-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 26px;
}
.content{
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1050px) {
  .main-column {
    padding: 0 16px;
  }
  .layout {
    padding-left: 0;
  }
}
</style>
