export const emptyHappStatistics = {
    usage:{
        cpu: "--",
        bandwidth: "--",
        storage: "--",
        sourceChains: "--",
        hosts: "--"
    },
    holofuel: {
        cpu: "--",
        bandwidth: "--",
        storage: "--",
        total: "--"
    }
}
